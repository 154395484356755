import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'app-frra-app',
  templateUrl: './frra-app.component.html',
  styleUrls: ['./frra-app.component.scss']
})
export class FrraAppComponent implements OnInit {
  isPlaying: boolean;
  src = '';
  a = new Audio('https://192.99.35.93/proxy/samhorga/?mp=/stream');

  // public percentLoaded: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {
  }

  ngOnInit() {
    this.isPlaying = false;
    this.src = this.a.src;
  }

  onAudioPlay() {
    this.a.src = 'https://usa5.fastcast4u.com/proxy/samhorga/stream';
    this.a.load();
    this.a.play();
    this.isPlaying = true;
  }

  onAudioPause() {
    this.a.pause();
    this.isPlaying = false;
  }
}
